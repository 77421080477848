// src/pages/HomePage.js
import React from "react";

function HomePage() {
  return (
    <>
      <style>
        {`
          html, body {
            margin: 0;
            padding: 0;
          }
        `}
      </style>
      <div>
        <div>
          <h1>Welcome to DD Dump</h1>
        </div>
        <p>Your trusted community for stock and crypto due diligence.</p>
      </div>
    </>
  );
}

export default HomePage;
