// src/pages/UserProfile.js
import React from "react";
import { useParams } from "react-router-dom";

function UserProfile() {
  let { username } = useParams();

  return (
    <div>
      <h1>User Profile: {username}</h1>
      <p>View and manage your profile and submissions here.</p>
    </div>
  );
}

export default UserProfile;
