import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  addDoc,
  collection,
  where,
} from "firebase/firestore";
import { app } from "../utils/firebase";
import { AdvancedChart } from "react-tradingview-embed";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function StockDetail() {
  const { ticker } = useParams();
  const [summary, setSummary] = useState("");
  const [ddList, setDdList] = useState([]);
  const [open, setOpen] = useState(false);
  const db = getFirestore(app);

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, "stocks", ticker);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSummary(docSnap.data().summary);
      } else {
        console.log("No such document!");
      }

      const querySnapshot = await getDocs(
        collection(db, "dd"),
        where("ticker", "==", ticker)
      );
      setDdList(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    }

    fetchData();
  }, [ticker, db]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitDD = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, "dd"), {
        ticker: ticker,
        content: event.target.dd.value,
        createdAt: new Date(),
      });
      handleClose();
      // Optionally, re-fetch DD list here
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h1>Details for: {ticker.toUpperCase()}</h1>
      <AdvancedChart
        widgetConfig={{
          symbol: ticker,
          width: "100%",
          height: "500px",
          locale: "en",
          theme: "light",
        }}
      />
      <Typography variant="h5" style={{ marginTop: 20 }}>
        Summary
      </Typography>
      <Typography paragraph>{summary}</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ marginBottom: 20 }}
      >
        Add Due Diligence
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500, // Specify width here directly
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Submit New Due Diligence</Typography>
          <form onSubmit={handleSubmitDD}>
            <TextField
              name="dd"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              variant="outlined"
              required
            />
            <Button type="submit" color="primary">
              Submit DD
            </Button>
          </form>
        </Box>
      </Modal>
      <Typography variant="h5">Due Diligence Submissions</Typography>
      {ddList.map((dd) => (
        <Card key={dd.id} variant="outlined" style={{ marginBottom: 8 }}>
          <CardContent>
            <Typography variant="h6">
              {new Date(dd.createdAt.seconds * 1000).toDateString()} -{" "}
              {dd.content.substring(0, 30)}...
            </Typography>
            <Typography>{dd.content}</Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default StockDetail;
