// src/pages/Dashboard.js
import React from "react";

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
      <p>Manage your posts and personal settings.</p>
    </div>
  );
}

export default Dashboard;
