// src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./utils/firebase";
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import UserProfile from "./pages/UserProfile";
import Dashboard from "./pages/Dashboard";
import StockDetail from "./pages/StockDetail";
import { AuthProvider } from "./utils/AuthContext";
import Navigation from "./components/Nagivation";

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth" element={<AuthPage />} />
          {/* Only show Dashboard if logged in */}
          {currentUser && <Route path="/dashboard" element={<Dashboard />} />}
          <Route path="/user/:username" element={<UserProfile />} />
          <Route path="/dd/:ticker" element={<StockDetail />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
