import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import "./Navigation.css";

const Navigation = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleLogout = async () => {
    try {
      await logout();
      // Optionally add redirection or a message here
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (search.trim()) {
      navigate(`/dd/${search}`);
      setSearch(""); // Clear the search input after navigating
    }
  };

  return (
    <nav className="navigation">
      <div className="nav-logo">
        <Link to="/">DD Dump</Link>
      </div>
      <div className="nav-links">
        <Link to="/">Home</Link>
        {currentUser ? (
          <>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/profile">Profile</Link>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <Link to="/auth">Login/Register</Link>
        )}
        <form onSubmit={handleSearchSubmit} className="search-form">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Ticker..."
          />
          <button type="submit">Search</button>
        </form>
      </div>
    </nav>
  );
};

export default Navigation;
